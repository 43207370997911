import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import {
  PhoneOutlined, MailOutlined, EnvironmentOutlined, InstagramOutlined,
  FacebookOutlined,  WhatsAppOutlined,
   SpotifyOutlined, 
  LinkedinOutlined, GithubOutlined, YoutubeOutlined, TikTokOutlined,
  PinterestOutlined, RedditOutlined, DiscordOutlined, SkypeOutlined,
   WechatOutlined
} from '@ant-design/icons'; 
import axios from 'axios';
import ButtonSheetModal from './ButtonSheet';
// import generatePdfFromData from './pdfConverter';

const ButtonGroup = ({ handleButtonClick }) => {
  const [userData, setUserData] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  // URL'den seri numarasını alma fonksiyonu
  const getSerialFromUrl = () => {
    const pathname = window.location.pathname;
    const serialNumber = pathname.slice(-9); // Son 9 karakteri al
    return serialNumber;
  };

  const fetchUserData = async () => {
    try {
      const serialNumber = getSerialFromUrl();
      const response = await axios.get(`${API_URL}/${serialNumber}`);
      setUserData(response.data.data);
      // console.log('User data:', response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Veriler yüklendiğinde buton içeriklerini güncelle
  const buttons = userData ? [
    userData.phones.find(s => s.type === "STANDARD") && {
      icon: <PhoneOutlined className="button-icon" />,
      label: 'Ara',
      type: 'phone',
      action: () => {
        const phone = userData.phones.find(s => s.type === "STANDARD");
        if (phone) {
            window.location.href = `tel:${phone.phoneNumber}`;
        } else {
            console.error("No phone number found with type 'STANDARD'");
        }
    },
    
      bgColor: '#52c41a', // Yeşil
    },
    userData.phones.find(s => s.type === "STANDARD") && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.35" d="M8 9h8m-8 4h6m4-9a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-5l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3z"/></svg>,
      label: 'SMS',
      type: 'sms',
      action: () => {
        const phone = userData.phones.find(s => s.type === "STANDARD");
        if (phone) {
          // SMS uygulamasını açar
          window.location.href = `sms:${phone.phoneNumber}`;
        } else {
          console.error("No phone number found with type 'STANDARD'");
        }
      },
      bgColor: '#7f7f7f',
    },    

    userData.emails[0] && {
      icon: <MailOutlined className="button-icon" />,
      label: 'E-Mail',
      type: 'email',
      content: userData.emails[0].email,
      bgColor: '#1890ff', // Mavi
    },
    userData.addresses[0] && {
      icon: <EnvironmentOutlined className="button-icon" />,
      label: 'Adres',
      type: 'address',
      content: userData.addresses[0].fullAddress, 
      bgColor: '#fa541c', // Kırmızı
    },
    userData.socials.find(s => s.type === "INSTAGRAM") && {
      icon: <InstagramOutlined className="button-icon" />,
      label: 'Instagram',
      action: () => window.open(userData.socials.find(s => s.type === "INSTAGRAM").link, '_blank'),
      bgColor: '#e91e63', // Pembe
    },
    userData.socials.find(s => s.type === "FACEBOOK") && {
      icon: <FacebookOutlined className="button-icon" />,
      label: 'Facebook',
      action: () => window.open(userData.socials.find(s => s.type === "FACEBOOK").link, '_blank'),
      bgColor: '#3b5998', // Mavi
    },
    userData.socials.find(s => s.type === "LINKEDIN") && {
      icon: <LinkedinOutlined className="button-icon" />,
      label: 'LinkedIn',
      action: () => window.open(userData.socials.find(s => s.type === "LINKEDIN").link, '_blank'),
      bgColor: '#0077b5', // LinkedIn Mavi
    },
    userData.socials.find(s => s.type === "GITHUB") && {
      icon: <GithubOutlined className="button-icon" />,
      label: 'GitHub',
      action: () => window.open(userData.socials.find(s => s.type === "GITHUB").link, '_blank'),
      bgColor: '#333', // GitHub Siyah
    },
    userData.socials.find(s => s.type === "YOUTUBE") && {
      icon: <YoutubeOutlined className="button-icon" />,
      label: 'YouTube',
      action: () => window.open(userData.socials.find(s => s.type === "YOUTUBE").link, '_blank'),
      bgColor: '#ff0000', // YouTube Kırmızı
    },
    userData.socials.find(s => s.type === "TIKTOK") && {
      icon: <TikTokOutlined className="button-icon" />,
      label: 'TikTok',
      action: () => window.open(userData.socials.find(s => s.type === "TIKTOK").link, '_blank'),
      bgColor: '#010101', // TikTok Siyah
    },
    userData.socials.find(s => s.type === "PINTEREST") && {
      icon: <PinterestOutlined className="button-icon" />,
      label: 'Pinterest',
      action: () => window.open(userData.socials.find(s => s.type === "PINTEREST").link, '_blank'),
      bgColor: '#bd081c', // Pinterest Kırmızı
    },
    userData.socials.find(s => s.type === "REDDIT") && {
      icon: <RedditOutlined className="button-icon" />,
      label: 'Reddit',
      action: () => window.open(userData.socials.find(s => s.type === "REDDIT").link, '_blank'),
      bgColor: '#ff4500', // Reddit Turuncu
    },
    userData.socials.find(s => s.type === "DISCORD") && {
      icon: <DiscordOutlined className="button-icon" />,
      label: 'Discord',
      action: () => window.open(userData.socials.find(s => s.type === "DISCORD").link, '_blank'),
      bgColor: '#7289da', // Discord Mavi
    },
    userData.socials.find(s => s.type === "SKYPE") && {
      icon: <SkypeOutlined className="button-icon" />,
      label: 'Skype',
      action: () => window.open(userData.socials.find(s => s.type === "SKYPE").link, '_blank'),
      bgColor: '#00aff0', // Skype Mavi
    },
    userData.socials.find(s => s.type === "WECHAT") && {
      icon: <WechatOutlined className="button-icon" />,
      label: 'WeChat',
      action: () => window.open(userData.socials.find(s => s.type === "WECHAT").link, '_blank'),
      bgColor: '#7bb32e', // WeChat Yeşil
    },
    userData.socials.find(s => s.type === "ONLYFANS") && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.35"><path d="M8.5 6a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13"/><path d="M8.5 15a2.5 2.5 0 1 1 0-5a2.5 2.5 0 0 1 0 5m5.5 1c2.5 0 6.42-1.467 7-4h-6c3-1 6.44-3.533 7-6h-4c-3.03 0-3.764-.196-5 1.5"/></g></svg>,
      label: 'OnlyFans',
      action: () => window.open(userData.socials.find(s => s.type === "ONLYFANS").link, '_blank'),
      bgColor: '#00AFF0', // 
    },
    userData.socials.find(s => s.type === "SPOTIFY") && {
      icon: <SpotifyOutlined className="button-icon" />,
      label: 'Spotify',
      action: () => window.open(userData.socials.find(s => s.type === "SPOTIFY").link, '_blank'),
      bgColor: '#1DB954', // Spotify Yeşil
    },
    userData.socials.find(s => s.type === "TELEGRAM") && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="m11.985 15.408l3.242 3.686c1.2 1.365 1.801 2.048 2.43 1.881c.628-.166.844-1.064 1.275-2.861l2.39-9.968c.665-2.768.997-4.151.259-4.834s-2.017-.175-4.575.84L5.14 8.865c-2.046.813-3.069 1.219-3.134 1.917a1 1 0 0 0 0 .214c.063.699 1.084 1.108 3.128 1.927c.925.371 1.388.557 1.72.912q.056.06.108.124c.306.38.436.88.697 1.876l.489 1.867c.253.97.38 1.456.713 1.522s.622-.336 1.201-1.141zm0 0l-.317-.33c-.362-.378-.543-.566-.543-.8s.18-.423.543-.8l3.573-3.724" color="white"/></svg>,
      label: 'Telegram',
      action: () => window.open(userData.socials.find(s => s.type === "TELEGRAM").link, '_blank'),
      bgColor: '#24A1DE', // Spotify  
    },
    userData.socials.find(s => s.type === "TRENDYOL") && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48"><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" d="M38.5 5.5h-29a4 4 0 0 0-4 4v29a4 4 0 0 0 4 4h29a4 4 0 0 0 4-4v-29a4 4 0 0 0-4-4"/><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" d="M32.827 24.526v2.183c0 .893-.724 1.617-1.617 1.617h0c-.447 0-.851-.18-1.144-.474"/><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" d="M32.827 21.857v2.669c0 .893-.724 1.617-1.617 1.617h0a1.617 1.617 0 0 1-1.618-1.617v-2.669"/><rect width="3.235" height="4.286" x="34.592" y="21.857" fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" rx="1.617" ry="1.617"/><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" d="M12.063 23.474c0-.893.724-1.617 1.617-1.617h0m-1.617 0v4.286M39.5 19.674v6.469m-30.15-5.62v4.811c0 .447.361.809.808.809h.242m-1.9-4.286h1.698m12.818 4.286v-2.669c0-.893-.724-1.617-1.618-1.617h0c-.893 0-1.617.724-1.617 1.617m0 2.669v-4.286m-1.921 3.47a1.62 1.62 0 0 1-1.404.816h0a1.617 1.617 0 0 1-1.618-1.617v-1.052c0-.893.724-1.617 1.618-1.617h0c.893 0 1.617.724 1.617 1.617V24h-3.235m13.098-.526c0-.893-.724-1.617-1.617-1.617h0c-.894 0-1.618.724-1.618 1.617v1.052c0 .893.724 1.617 1.617 1.617h0c.894 0 1.618-.724 1.618-1.617m0 1.617v-6.469M42.5 34h-37m37-20h-37"/></svg>,
      label: 'Trendyol',
      action: () => window.open(userData.socials.find(s => s.type === "TRENDYOL").link, '_blank'),
      bgColor: '#f27a1a', 
    },
    userData.socials.find(s => s.type === "SAHIBINDEN") && {
      icon:<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path fill="#ffffff" d="M11.517 4.723c.563-.007 1.13-.004 1.69.063c2.412.054 4.853 2.18 4.879 4.508h-3.319c.009-.694-.603-1.555-1.279-1.732c-1.105-.269-2.46-.355-3.43.294c-.738.445-1.065 1.672-.095 2.056c2.288 1.083 5.158.846 7.224 2.372c1.698 1.21 1.598 3.666.274 5.086c-1.718 1.84-4.636 2.132-7.099 1.782c-2.448-.117-4.755-2.245-4.819-4.562h3.311c-.056.832.638 1.557 1.46 1.822c1.27.275 2.726.358 3.93-.19c.96-.323 1.024-1.544.284-2.103c-1.595-.897-3.565-.924-5.297-1.518c-2.012-.39-3.643-2.278-3.26-4.197c.424-2.342 3.127-3.727 5.546-3.681"/></svg>
        ,
      label: 'Sahibinden',
      action: () => window.open(userData.socials.find(s => s.type === "SAHIBINDEN").link, '_blank'),
      bgColor: '#fae629', 
    },
    userData.socials.find(s => s.type === "BOOKING") && {
      icon:<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><g fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8"><path d="M4 18V8.5A4.5 4.5 0 0 1 8.5 4h7A4.5 4.5 0 0 1 20 8.5v7a4.5 4.5 0 0 1-4.5 4.5H6a2 2 0 0 1-2-2"/><path d="M8 12h3.5a2 2 0 1 1 0 4H8V9a1 1 0 0 1 1-1h1.5a2 2 0 1 1 0 4H9m7 4h.01"/></g></svg>
        ,
      label: 'Booking',
      action: () => window.open(userData.socials.find(s => s.type === "BOOKING").link, '_blank'),
      bgColor: '#003580', 
    },
    userData.socials.find(s => s.type === "TRIPADVISOR") && {
      icon:<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 32 32"><path fill="white" d="M30.683 12.708c.375-1.609 1.568-3.219 1.568-3.219h-5.349c-3.005-1.943-6.647-2.968-10.688-2.968c-4.187 0-7.968 1.041-10.953 3H.252s1.176 1.583 1.556 3.181C.835 14.046.252 15.666.252 17.447c0 4.416 3.599 8.011 8.015 8.011c2.527 0 4.765-1.183 6.245-3.005l1.697 2.552l1.724-2.584a7.85 7.85 0 0 0 2.937 2.324c1.943.88 4.125.979 6.125.239c4.141-1.536 6.26-6.161 4.74-10.301a7.8 7.8 0 0 0-1.079-1.98zm-4.23 10.765a6.32 6.32 0 0 1-4.891-.192a6.33 6.33 0 0 1-2.651-2.303c-.272-.4-.5-.833-.672-1.296c-.199-.527-.292-1.068-.344-1.62c-.099-1.109.057-2.229.536-3.271a6.4 6.4 0 0 1 3.604-3.328c3.319-1.219 7 .484 8.219 3.791c1.224 3.308-.479 6.991-3.781 8.215h-.02zm-12.89-2.446a6.4 6.4 0 0 1-5.297 2.817c-3.525 0-6.401-2.875-6.401-6.396s2.876-6.401 6.401-6.401c3.527 0 6.396 2.88 6.396 6.401c0 .219-.036.416-.063.64c-.109 1.079-.453 2.1-1.036 2.959zm-9.366-3.663c0 2.188 1.781 3.959 3.964 3.959s3.959-1.771 3.959-3.959a3.959 3.959 0 0 0-7.918 0zm16.068 0a3.956 3.956 0 1 0 3.953-3.952a3.963 3.963 0 0 0-3.959 3.952zm-14.697 0a2.594 2.594 0 0 1 2.583-2.593c1.417 0 2.599 1.167 2.599 2.593a2.6 2.6 0 0 1-5.203 0zm16.047 0a2.593 2.593 0 0 1 2.593-2.593a2.597 2.597 0 0 1-.015 5.193a2.597 2.597 0 0 1-2.6-2.6zm-5.407-9.443c2.88 0 5.48.516 7.761 1.548a8.1 8.1 0 0 0-2.543.479a7.9 7.9 0 0 0-4.5 4.167c-.416.88-.635 1.812-.719 2.755a8.03 8.03 0 0 0-7.844-7.437c2.281-.979 4.928-1.511 7.787-1.511z"/></svg>
        ,
      label: 'Tripadvisor',
      action: () => window.open(userData.socials.find(s => s.type === "TRIPADVISOR").link, '_blank'),
      bgColor: '#00af87', 
    },
    userData.socials.find(s => s.type === "AIRBNB") && {
      icon:<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 18.775c-1.656-2-3-3.24-3-5.275s1.503-3.5 3.003-3.5S15 11.464 15 13.5s-1.344 3.275-3 5.275m0 0c-2 2.545-5.98 2.687-7.65 1.527c-1.671-1.16-1.695-3.562-.596-6.107c1.1-2.544 2.565-5.597 5.497-9.668C10.212 3.458 10.897 3 11.997 3M12 18.775c2 2.545 5.98 2.687 7.65 1.527c1.671-1.16 1.695-3.562.596-6.107c-1.1-2.544-2.565-5.597-5.497-9.668C13.788 3.458 13.103 3 12.003 3" color="white"/></svg>
        ,
      label: 'Airbnb',
      action: () => window.open(userData.socials.find(s => s.type === "AIRBNB").link, '_blank'),
      bgColor: '#FF5A5F', 
    },
    userData.socials.find(s => s.type === "HEPSIBURADA") && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48"><circle cx="24" cy="24" r="21.5" fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round"/><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" d="M8.5 14.526v8.1m0-3.4c0-1.1.9-2 2-2s2 .9 2 2v3.3"/><circle cx="32.5" cy="14.826" r=".7" fill="white"/><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" d="M32.5 17.226v5.4m-5.6-.4c.4.3.8.4 1.6.4h.4c.7 0 1.3-.6 1.3-1.3h0c0-.7-.6-1.3-1.3-1.3H28c-.7 0-1.3-.6-1.3-1.3h0c0-.7.6-1.3 1.3-1.3h.4c.9 0 1.3.1 1.6.4m-11.8 3.8c-.3.6-1 1-1.7 1h0c-1.1 0-2-.9-2-2v-1.3c0-1.1.9-2 2-2h0c1.1 0 2 .9 2 2v.7h-4m6 .574c0 1.1.9 2 2 2h0c1.1 0 2-.9 2-2v-1.3c0-1.1-.9-2-2-2h0c-1.1 0-2 .9-2 2m0-2.1v8m-5.997 2.9v3.3c0 1.1.9 2 2 2s2-.9 2-2v-3.3m0 3.4v2m2.42-3.4c0-1.1.9-2 2-2m-2 0v5.4m7.867-2c0 1.1-.9 2-2 2h0c-1.1 0-2-.9-2-2v-1.3c0-1.1.9-2 2-2h0c1.1 0 2 .9 2 2m-.001 3.3v-5.4m6.001 2c0-1.1-.9-2-2-2h0c-1.1 0-2 .9-2 2v1.3c0 1.1.9 2 2 2h0c1.1 0 2-.9 2-2m-.001 2.1v-8M8.5 30.1c0-1.1.9-2 2-2h0c1.1 0 2 .9 2 2v1.3c0 1.1-.9 2-2 2h0c-1.1 0-2-.9-2-2m0 2.1v-8m32.29 6c0 1.1-.9 2-2 2h0c-1.1 0-2-.9-2-2v-1.3c0-1.1.9-2 2-2h0c1.1 0 2 .9 2 2m-.001 3.3v-5.4"/><path fill="none" stroke="white" d="M8.4 38.835h31.156m-15.558.084v6.545m-8-6.545v5.059m16-5.059v5.059"/></svg>,
      label: 'HepsiBurada',
      action: () => window.open(userData.socials.find(s => s.type === "HEPSIBURADA").link, '_blank'),
      bgColor: '#ff7f00', 
    },
    userData.cryptos[0] && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><g fill="none" fillRule="evenodd"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path fill="white" d="M17.42 3a2 2 0 0 1 1.649.868l.087.14L22.49 9.84a2 2 0 0 1-.208 2.283l-.114.123l-9.283 9.283a1.25 1.25 0 0 1-1.666.091l-.102-.09l-9.283-9.284a2 2 0 0 1-.4-2.257l.078-.15l3.333-5.832a2 2 0 0 1 1.572-1.001L6.58 3zm0 2H6.58l-3.333 5.833L12 19.586l8.753-8.753zM15 6a1 1 0 1 1 0 2h-2v1.545c.758.07 1.447.217 2.004.426c.395.148.749.336 1.013.571s.483.557.483.958s-.219.724-.483.958c-.264.235-.618.423-1.013.57c-.594.223-1.338.377-2.157.44A1 1 0 0 1 13 14v2a1 1 0 1 1-2 0v-2c0-.196.056-.378.153-.532c-.819-.063-1.563-.216-2.157-.44c-.395-.147-.749-.335-1.013-.57S7.5 11.901 7.5 11.5s.219-.724.483-.958c.264-.235.618-.423 1.013-.57c.556-.21 1.245-.357 2.004-.427V8H9a1 1 0 1 1 0-2zm-2.001 4.55a1 1 0 0 1-1.998 0a6.8 6.8 0 0 0-1.654.357c-.33.124-.56.259-.701.383c-.117.104-.14.172-.145.199L8.5 11.5c0 .013.005.085.146.21s.372.26.701.382c.655.246 1.592.408 2.653.408s1.998-.162 2.653-.408c.329-.123.56-.258.7-.382a.46.46 0 0 0 .14-.178l.007-.032l-.007-.032a.46.46 0 0 0-.14-.178c-.14-.124-.371-.26-.7-.382c-.44-.165-1.008-.293-1.654-.358"/></g></svg>,
      label: 'Crypto',
      type: 'crypto',
      content: {
        title: userData.cryptos[0].title,
        address: userData.cryptos[0].address,
        description :userData.cryptos[0].description,
      },
      bgColor: '#26a17b', // Mavi
    },
    // userData.addresses[0] && {
    //   icon: <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 32 32"><path fill="white" d="M16 18a5 5 0 1 1 5-5a5.006 5.006 0 0 1-5 5m0-8a3 3 0 1 0 3 3a3.003 3.003 0 0 0-3-3"/><path fill="white" d="m16 30l-8.436-9.949a35 35 0 0 1-.348-.451A10.9 10.9 0 0 1 5 13a11 11 0 0 1 22 0a10.9 10.9 0 0 1-2.215 6.597l-.001.003s-.3.394-.345.447ZM8.813 18.395s.233.308.286.374L16 26.908l6.91-8.15c.044-.055.278-.365.279-.366A8.9 8.9 0 0 0 25 13a9 9 0 1 0-18 0a8.9 8.9 0 0 0 1.813 5.395"/></svg>,
    //   label: 'Konum',
    //   action: () => {
    //     const address = encodeURIComponent(userData.addresses[0].fullAddress); 
    //     window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank'); 
    //   },
    //   bgColor: '#4285f4', // Google Mavi
    // },
    userData.phones.find(s => s.type === "WP") && {
      icon: <WhatsAppOutlined className="button-icon" />,
      label: 'WhatsApp',
      action: () => window.open(`https://wa.me/${userData.phones.find(s => s.type === "WP").phoneNumber}`, '_blank'),
      bgColor: '#25D366', // WhatsApp Yeşil
    },
    userData.phones.find(s => s.type === "WPB") && {
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><g fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" color="white"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12c0 1.379.28 2.693.784 3.888c.279.66.418.99.436 1.24c.017.25-.057.524-.204 1.073L2 22l3.799-1.016c.549-.147.823-.22 1.073-.204c.25.018.58.157 1.24.436A10 10 0 0 0 12 22"/><path d="M12.882 12C14.052 12 15 13.007 15 14.25s-.948 2.25-2.118 2.25h-2.47c-.666 0-.998 0-1.205-.203S9 15.768 9 15.115V12m3.882 0C14.052 12 15 10.993 15 9.75s-.948-2.25-2.118-2.25h-2.47c-.666 0-.998 0-1.205.203S9 8.232 9 8.885V12m3.882 0H9"/></g></svg>,
      label: 'Business',
      action: () => window.open(`https://wa.me/${userData.phones.find(s => s.type === "WPB").phoneNumber}`, '_blank'),////düzenlenecekkk
      bgColor: '#128C7E', //whatsapp businness
    },
    
  ].filter(Boolean) : [];

  // Satırları ayarlamak için
  const rows = [];
  for (let i = 0; i < buttons.length; i += 3) {
    rows.push(buttons.slice(i, i + 3));
  }

  return (
    <>
      {rows.map((rowButtons, rowIndex) => (
        <Row
          key={rowIndex}
          gutter={[16, 16]}
          className={(rowButtons.length === 2 || rowButtons.length === 1) ? 'two-buttons-row' : ''}
        >
          {rowButtons.map((button, index) => (
            <Col span={8} key={index}>
              <div className="button-wrapper">
                <Button
                  type="primary"
                  className="button"
                  style={{ backgroundColor: button.bgColor }} // Arka plan rengini ayarla
                  onClick={() => button.action ? button.action() : handleButtonClick({ type: button.type, content: button.content, label: button.label })}>
                  {button.icon}
                  {button.label}
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export default ButtonGroup;
